






import Vue from 'vue'

export default Vue.extend({
    name: 'PendingUserAvatar',
    props: {
        email: String,
    },
})
