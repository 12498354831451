
































import Vue from 'vue'
import { BaseCard, BaseCardTitle, BaseCardBody } from '@/components'
import InvitationStatus from './InvitationStatus.vue'
import UserInvitationForm from './UserInvitationForm.vue'
import { IInvitation, IInvitationGroup } from '@/types'
import { BaseCircleBtn } from '@/components'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

export default Vue.extend({
    name: 'InvitationGroup',
    components: {
        BaseCard,
        BaseCardTitle,
        BaseCardBody,
        BaseCircleBtn,
        InvitationStatus,
        UserInvitationForm,
    },

    data: () => ({
        faPlus,
        isFormShown: false,
    }),
    props: {
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String as () => IInvitationGroup,
            required: true,
        },
        templateMessage: {
            type: String,
            required: true,
        },
        category: {
            type: String,
            required: false,
        },
        invites: {
            type: Array as () => IInvitation[],
            required: true,
        },
        limit: {
            type: Number,
            default: 3,
        },
    },
    methods: {
        onInvitationSent() {
            this.isFormShown = false
            this.$emit('sent')
        },
    },
    computed: {},
})
