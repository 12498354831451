























import { BaseCard, BaseCardBody } from '@/components'
import { InvitationGroup } from './components'
import {
    REPOSITORY_INVITATIONS,
    REPOSITORY_INVITATION_TEMPLATES,
    REPOSITORY_USER_SKILLS,
} from '@/repositiories'
import { IInvitation, IInvitationTemplate, IInvitationGroup, IUserSkill } from '@/types'
import Vue from 'vue'

export default Vue.extend({
    name: 'PlatformInvitationMain',
    components: {
        InvitationGroup,
        BaseCard,
        BaseCardBody,
    },
    data: () => ({
        invitations: [] as IInvitation[],
        invitationTemplates: [] as IInvitationTemplate[],
        coreSkills: [] as IUserSkill[],
    }),
    mounted() {
        this.fetchInvitations()
        this.fetchInvitationTemplates()
        this.fetchCoreSkills()
    },
    methods: {
        async fetchInvitations() {
            this.invitations = await REPOSITORY_INVITATIONS.fetchInvitations()
        },
        async fetchInvitationTemplates() {
            this.invitationTemplates = await REPOSITORY_INVITATION_TEMPLATES.fetchInvitationTemplates()
        },
        async fetchCoreSkills() {
            this.coreSkills = await REPOSITORY_USER_SKILLS.fetchCoreUserSkills(3)
        },
        getInvitationsGroup: function (name: string, group: IInvitationGroup) {
            return {
                name,
                type: group,
                invitations: this.invitations.filter((invitation) => invitation.group == group),
                templateMessage:
                    this.invitationTemplates.find((template) => template.group == group)
                        ?.message || '',
            }
        },
        getSkillGroups() {
            return this.coreSkills.map((skill) => {
                const args = this.getInvitationsGroup(
                    `Experts in ${skill.skillName}`,
                    IInvitationGroup.SKILL
                )
                return {
                    ...args,
                    templateMessage: args.templateMessage.replace(
                        /{skillName}/g,
                        skill.skillName
                    ),
                    invitations: args.invitations.filter(
                        (invitation) => invitation.metadata?.skill == skill.skillName
                    ),
                    category: skill.skillName,
                }
            })
        },
        getGroups() {
            return [
                ...this.getSkillGroups(),
                this.getInvitationsGroup('Top people', IInvitationGroup.TOP_PEOPLE),
                this.getInvitationsGroup('Close friends', IInvitationGroup.CLOSE_FRIENDS),
                this.getInvitationsGroup('Open to new work', IInvitationGroup.LOOKING),
            ]
        },
        refresh() {
            this.fetchInvitations()
        },
    },
    computed: {},
})
