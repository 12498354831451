export enum IInvitationGroup {
    SKILL = 'skill',
    TOP_PEOPLE = 'top_people',
    CLOSE_FRIENDS = 'close_friends',
    LOOKING = 'looking',
}

export enum InvitationStatus {
    NEW = 'new',
    ACCEPTED = 'accepted',
    DECLINED = 'declined',
}

export enum InvitationType {
    REFERRAL = 'referral',
    USER = 'user',
    INFLUENCER = 'influencer',
}

export interface IInvitation {
    id: string
    uuid: string
    inviterId: number
    firstName: string
    lastName: string
    email: string
    group: IInvitationGroup
    skill: string
    status: InvitationStatus
    created: Date
    modified: Date | null
    type: InvitationType | null
    user: {
        id: string
        firstName: string
        lastName: string
        userImage: string
    }
    metadata: { skill?: string }
}

export interface IInvitationPayload {
    email: string
    message: string
    group: IInvitationGroup
    metadata: { skill?: string }
}
