



























import Vue from 'vue'
import { BaseBtn, BaseFieldEmail, BaseFieldSelect, BaseFieldTextarea } from '@/components'
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import { REPOSITORY_INVITATIONS, REPOSITORY_SKILLS } from '@/repositiories'
import { IInvitationGroup } from '@/types'

export default Vue.extend({
    name: 'UserInvitationForm',
    components: {
        BaseBtn,
        BaseFieldEmail,
        BaseFieldSelect,
        BaseFieldTextarea,
    },
    data: () => ({
        isValid: false,
        selectedCategory: undefined as undefined | string,
        categories: [] as String[],
        iconPaperPlane: faPaperPlane,
        payload: {
            email: '',
            message: '',
            metadata: {},
        },
    }),
    props: {
        group: {
            type: String as () => IInvitationGroup,
            required: true,
        },
        templateMessage: {
            type: String,
            required: true,
        },
        category: {
            type: String,
            required: false,
        },
    },
    methods: {
        async fetchSkillsCategories(searchText: string) {
            const { items } = await REPOSITORY_SKILLS.fetchSkillsCategories({
                searchText,
                limit: 40,
            })
            this.categories = items.map((item) => item.name)
        },
        async sendInvitation() {
            try {
                await REPOSITORY_INVITATIONS.createInvitation({
                    email: this.payload.email,
                    message: this.payload.message,
                    group: this.group,
                    metadata: { skill: this.category },
                })
                this.$emit('sent')
            } catch (error) {
                alert(error.errorMessage)
            }
        },
        submit() {
            this.sendInvitation()
        },
        preselectCategory() {
            if (this.group == IInvitationGroup.SKILL) {
                this.selectedCategory = `Expert in ${this.category}`
                this.categories.push(this.selectedCategory)
            }
        },
    },
    created() {
        this.$data.payload.message = this.templateMessage
    },
    computed: {
        shouldShowCategory() {
            return this.group != IInvitationGroup.SKILL
        },
        formIsValid() {
            return this.isValid && this.payload.message.length > 0
        },
    },
    async mounted() {
        await this.fetchSkillsCategories('')
        this.preselectCategory()
    },
})
