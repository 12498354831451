













import Vue from 'vue'
import { IInvitation, InvitationStatus, IUserSkill } from '@/types'
import { REPOSITORY_SKILLS } from '@/repositiories'
import { BaseAvatar } from '@/components'
import PendingUserAvatar from './PendingUserAvatar.vue'

export default Vue.extend({
    name: 'InvitationStatus',
    components: { BaseAvatar, PendingUserAvatar },

    data: () => ({}),
    props: {
        invitation: {
            type: Object as () => IInvitation,
            required: true,
        },
    },
    methods: {},
    computed: {
        isPending(): boolean {
            return this.invitation.status == InvitationStatus.NEW
        },
    },
})
